import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { WeboldalService } from '../../../services/weboldal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mol',
  templateUrl: './molmove.component.html',
  styleUrls: ['./molmove.component.scss']
})
export class MolMoveComponent implements OnInit {

  formGroup: FormGroup;
  ugyfelElegedettsegLista: any[] = [];
  weboldalDokumentumok: string = "";
  utasKalkLink: string = "";
  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private utilityService: UtilityService,
    public weboldalService: WeboldalService,
    private router: Router) { }

  ngOnInit(): void {
    this.linkek();
    this.formGroupInit();
    this.scriptek();
    this.ugyfelElegedettseg();
  }

  async linkek() {

    let model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);

    let modelLink = {
      key: "UtasKalkLink"
    }
    this.utasKalkLink = await this.utilityService.appsettingsKeyErtekLekerese(modelLink);   
  }

  scrollToelement(element: string) {
    document.getElementById(element).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  scriptek() {
    let script = this._renderer2.createElement('script');
    script.text = `var swiper = new Swiper(".TestimonialSlider", {
      slidesPerView: 1,
      spaceBetween: 16,
	  navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
    });`;

    this._renderer2.appendChild(this._document.body, script);

    script = this._renderer2.createElement('script');
    script.text = `(() => {
                'use strict'
                const forms = document.querySelectorAll('.needs-validation')
                Array.from(forms).forEach(form => {
                  form.addEventListener('submit', event => {
                    if (!form.checkValidity()) {
                      event.preventDefault()
                      event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                  }, false)
                })
              })()`;

    this._renderer2.appendChild(this._document.body, script);
  }

  formGroupInit() {

    this.formGroup = this.formBuilder.group({
      f_kuponkod: new FormControl(null)
    });
  }

  validateInput(field: HTMLInputElement, control_nev: string) {

    const control = this.formGroup.controls[control_nev];

    if (control.errors) {
      for (let error in control.errors)
        field.setCustomValidity(
          control.errors[error]
        );
    } else {
      // No error
      field.setCustomValidity('');
    }
  }

  ugyfelElegedettseg() {

    this.ugyfelElegedettsegLista = [];

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('weblap_ugyfelElegedettseg').toPromise().then(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.weboldalService.ugyfelElegedettseg().subscribe(
            data => {
              if (data && data.length > 0) {

                this.ugyfelElegedettsegLista = data;
              }
              else {
                this.formGroup.controls["f_irsz"].setErrors({ nincsTelepules: true });
              }
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a települések lekérdezése közben",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  kalkulalok() {

    //let helyes: boolean = this.kuponkodEllenor();
    let model = {
      kuponkod: this.formGroup.controls["f_kuponkod"].value
    }
    this.weboldalService.utasMolKupnokodEllenor(model).subscribe(
      adat => {
        if (adat["f_kuponkod_helyes"] == "N") {
          const dialogRef = this.utilityService.dialogMegnyitasa({
            confirm: true,
            title: 'Nincs vagy nem érvényes kuponkód',
            message: 'A megadott kuponkód nem megfelelő. Kérlek vedd figyelembe, hogy ha folytatod, nem jutsz hozzá a MOL Move kuponhoz és nem veszel részt a sorsolásban.<br>Biztosan így szeretnéd folytatni?',
            cancelButtonText: 'Mégsem',
            confirmButtonText: 'Megkötöm kupon nélkül'
          });
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.formGroup.controls["f_kuponkod"].setValue(null);
              this.tovabb();
            }
          });
        }
        else {
          this.tovabb();
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a kuponkód ellenőrzése közben",
          confirmButtonText: 'Rendben'
        });
      });    
  }

  tovabb() {
    
      let model = {
        f_link: this.utasKalkLink + "?alkusz=0623" + (this.formGroup.controls["f_kuponkod"].value ? "&k=" + encodeURIComponent(btoa(this.formGroup.controls["f_kuponkod"].value)) : "")
      }
      this.weboldalService.linkHash(model).subscribe(
        adat => {
          window.open(adat, "_self");
        },
        errorResult => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a link lekérése közben",
            confirmButtonText: 'Rendben'
          });
        });
    
  }

  kuponkodEllenor(): boolean {

    var helyes: boolean = false;

    let model = {
      kuponkod: this.formGroup.controls["f_kuponkod"].value
    }
    this.weboldalService.utasMolKupnokodEllenor(model).subscribe(
      adat => {
        helyes = adat["f_kuponkod_helyes"]
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a kuponkód ellenőrzése közben",
          confirmButtonText: 'Rendben'
        });
      });

    return helyes;
  }
}
