<section class="bg-light pb-5 pt-5">
  <div class="container pt-5">
    <div class="row pt-4 justify-content-center">
      <div class="col-12 col-xl-10 text-primary d-flex flex-column flex-xl-row justify-content-between">
        <h1 class="bold">Kapcsolat</h1>
        <div class="d-flex flex-column flex-xl-row">
          <a class="btn btn-lg rounded-pill btn-outlined-primary mx-1 mb-3" href="{{onlineFizetesLink}}" target="_blank">BANKKÁRTYÁS FIZETÉS</a>
          <a class="btn btn-lg rounded-pill btn-outlined-primary mx-1 mb-3" href="https://kalk.granitbiztosito.hu/ugyfel_dokumentum/ugyfel_dokumentum_igenyles.aspx" target="_blank">DOKUMENTUM IGÉNYLÉS</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-light pt-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-9">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 text-primary">
              <h5>Központi Ügyfélszolgálat:</h5>
              <table class="my-4">
                <tr class="py-3">
                  <td class="pe-3">
                    Cím:
                  </td>
                  <td class="px-3">
                    1211 Budapest, Szállító utca 4.
                  </td>
                </tr>
                <tr class="py-3">
                  <td class="pe-3">
                    Tel.:
                  </td>
                  <td class="px-3">
                    (+36-1) 666-62-00
                  </td>
                </tr>                
                <tr class="py-3">
                  <td class="pe-3">
                    E-mail:
                  </td>
                  <td class="px-3">
                    ugyfelszolgalat@granitbiztosito.hu
                  </td>
                </tr>
              </table>
              <p>
                Felhívjuk ügyfeleink szíves figyelmét, hogy az ügyfélszolgálati helyiség kamerával védett. A Biztosító által alkalmazott elektronikus megfigyelő és képrögzítő rendszerről szóló adatkezelési tájékoztató itt olvasható.
              </p>
              <p>
                Felhívjuk figyelmét, hogy hétfői munkanapon 17:00 -19:00 óra között csak időpontfoglalással rendelkező ügyfeleinket tudjuk fogadni.<br />
                Megértését és együttműködését köszönjük!
              </p>
            </div>
            <div class="col-xl-6">
              <div class="rounded-3 bg-info rounded-bottom-0">
                <div class="ratio ratio-1x1 rounded-3 rounded-bottom-0">
                  <div class="rounded-3 rounded-bottom-0 d-flex align-items-center justify-content-center opacity-75" id="map">

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-info pb-4 pb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-9">
        <div class="container">
          <div class="row justify-content-center mb-3">
            <div class="col-xl-6 text-primary pt-4 order-2 order-xl-1">
              <h5 class="pt-5">Nyitva tartás:</h5>
              <table class="my-4">
                <tr class="py-3">
                  <td class="pe-3" style="vertical-align:top">
                    Hétfő:
                  </td>
                  <td class="px-3">
                    7:00-19:00
                  </td>
                </tr>
                <tr class="py-3">
                  <td class="pe-3">
                    Kedd:
                  </td>
                  <td class="px-3">
                    8:00-16:00
                  </td>
                </tr>
                <tr class="py-3">
                  <td class="pe-3">
                    Szerda:
                  </td>
                  <td class="px-3">
                    8:00-16:00
                  </td>
                </tr>
                <tr class="py-3">
                  <td class="pe-3">
                    Csütörtök:
                  </td>
                  <td class="px-3">
                    8:00-16:00
                  </td>
                </tr>
                <tr class="py-3">
                  <td class="pe-3">
                    Péntek:
                  </td>
                  <td class="px-3">
                    8:00-15:00
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-xl-6  order-1 order-xl-2">
              <div class="p-4 d-flex bg-primary rounded-3 rounded-top-0">
                <div class="p-2">
                  <img src="assets/images/map-icon.svg">
                </div>
                <div class="text-white p-2">
                  <h5 class="mb-5">Időpont foglalás személyes<br>ügyfélszolgálatra</h5>
                  <p>
                    <a href="{{idopontUsziLink}}" target="_blank" class="btn btn-lg rounded-pill btn-secondary">Időpontfoglalás</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-6 text-primary  order-3 order-xl-3">
              <h5>Pénztár nyitva tartása:</h5>
              <table class="my-4">
                <tr class="py-3">
                  <td class="pe-3" style="vertical-align:top">
                    Hétfő - Csütörtök:
                  </td>
                  <td class="px-3">
                    8:00-16:00
                  </td>
                </tr>
                <tr class="py-3">
                  <td class="pe-3">
                    Péntek:
                  </td>
                  <td class="px-3">
                    8:00-14:00
                  </td>
                </tr>
              </table>
              <p>Ünnepi időszak esetén az eltérő nyitvatartási időpontokat a <a [routerLink]="['/hirek']">hírek</a> között találja meg.</p>
            </div>
            <div class="col-xl-6 text-primary  order-4 order-xl-4">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
