<section class="bg-white intro">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 termekek">

        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">KÖTELEZŐ GÉPJÁRMŰ-FELELŐSSÉGBIZTOSÍTÁS</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <table class="kgfb casco_result" style="width: 100%;">
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>Egyszerűsített díjkalkuláció</b>
                      </p>
                      <p class="question">Amennyiben Ön olyan Magyarországon forgalomba helyezett gépjárműre kíván díjkalkulációt készíteni, amelyre Önt jegyezték be üzembentartóként, akkor válassza az egyszerűsített tarifálás szolgáltatásunkat.</p>
                      <p class="question">Ennek segítségével a gépjármű forgalmi rendszámának és a forgalmi engedély számának megadása, valamint előzetes tájékoztatásán alapuló hozzájárulása birtokában társaságunk, mint adatigénylő, a gépjármű és az üzembentartó közúti közlekedési nyilvántartási (gépjárműnyilvántartási) adatainak lekérdezését és az adategyezőség fennállását követően kiszámolja a kötelező gépjármű-felelősségbiztosításának díját.</p>

                      <div>
                        <p>
                        <a href="https://kalk.granitbiztosito.hu/kalkulator2024/kgfb_kalk_gyors1.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">2024. évi Egyszerűsített díjkalkuláció és kötés</a>
                          </p>
                          </div>
                      
                      <div>
                        <p>
                        <a href="https://kalk.granitbiztosito.hu/kalkulator2025/kgfb_kalk_gyors1.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">2025. évi Egyszerűsített díjkalkuláció és kötés</a>
                        </p>
                      </div>
                      <div class="clr"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p>&nbsp;</p>
          <div class="container">
            <div class="row justify-content-center">
              <table class="kgfb casco_result" style="width: 100%;">
                <tbody>
                  <tr>
                    <td>
                      <p><b>Hagyományos díjkalkuláció</b></p>
                      <p class="question">A hagyományos díjkalkuláció során minden olyan adat bekérésre kerül, amit a biztosítási ajánlat elkészítéséhez szükséges. Ezt a kötési módot ajánljuk, amennyiben Ön új vagy használt gépjárművet vásárolt, vagy annak üzembentartójává vált, forgalomba még nem helyezett, vagy forgalomból kivont gépjárműre szeretne szerződést kötni, vagy Önnek díjnemfizetés miatt szűnt meg az előző szerződése. Az alábbi gombra kattintva megkezdheti az adatok megadását.</p>

                      <div class="wb_button_holder2">
                        <p>
                        <a href="https://kalk.granitbiztosito.hu/kalkulator2024/kgfb_kalk1.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">2024. évi díjkalkuláció és kötés</a>
                        </p>
                      </div>
                      <div class="wb_button_holder2">
                        <p>
                          <a href="https://kalk.granitbiztosito.hu/kalkulator2025/kgfb_kalk1.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">2025. évi díjkalkuláció és kötés</a>
                        </p>
                      </div>
                      <div class="clr"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p>&nbsp;</p>
          <div class="container">
            <div class="row justify-content-center">
              <table class="kgfb casco_result" style="width: 100%;">
                <tbody>
                  <tr>
                    <td>
                      <p><b>Díjkalkuláció meglévő ügyfeleink részére</b></p>
                      <p class="question">Amennyiben Ön jelenleg is rendelkezik biztosító társaságunknál az adott gépjárműre kötelező gépjármű-felelősségbiztosítással és a biztosítási évforduló előtti 60 napos időszakon belül szeretné megtudni, hogy a következő biztosítási időszakra társaságunk milyen biztosítási díjat ajánl Önnek, akkor az alábbi gombra kattintva megtudhatja.</p>
                      <div class="wb_button_holder2">
                        <a href="https://kalk.granitbiztosito.hu/kgfbindex/indexdij_adatok.aspx" target="_blank" class="btn btn-lg rounded-pill btn-secondary">DÍJKALKULÁCIÓ JELENLEGI ÜGYFELEINKNEK</a>
                      </div>
                      <div class="clr"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section class="bg-white mb-4 py-4 article-section">
          <div class="container">
            <div class="row justify-content-center">
              <ul>
                <li><a href="https://felugyelet.mnb.hu/fogyasztoknak/tematikus_oldalak/kgfb" target="_blank">A Magyar Nemzeti Bank tájékoztatása - Fókuszban a kötelező gépjármű biztosítás</a></li>
                <li>
                  <a href="{{weboldalDokumentumok}}pszaf_kotgepjfelbizt_net.pdf" target="_blank">
                    PSZÁF tájékoztatók –
                    Röviden a gépjármű biztosításról (KGFB)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p>&nbsp;</p>
          <div class="container">
            <div class="row justify-content-center">
              <table class="kgfb casco_result" style="width: 100%;">
                <tbody>
                  <tr>
                    <td>
                      <p><strong>Tisztelt Ügyfeleink!</strong></p>
                      <p>A Biztosító 2025. március 1-jétől hatályos díjtarifájával kapcsolatban felhívjuk szíves figyelmüket, hogy a kötelező gépjármű-felelősségbiztosításról szóló 2009. évi LXII. törvény (Gfb. törvény) 23. § (4a) bekezdése alapján a díjtarifa csak a Biztosító meglévő – a következő biztosítási időszak első napjától kezdődő hatállyal a biztosítási időszak kezdőnapján alkalmazandó díjtarifa szerint módosuló szerződéssel rendelkező – szerződő ügyfelei javára tartalmazhat különbségtételt abból a szempontból, hogy a szerződés új szerződésnek vagy pedig a Gfb törvény 24. § (1) bekezdése szerint módosuló szerződésnek minősül.</p>
                      <p>A Biztosító a meglévő szerződőit a részükre küldött írásos értesítőben külön tájékoztatja, hogy a következő biztosítási időszak első napjától kezdődő hatállyal a meglévő szerződésük a biztosítási időszak kezdőnapján alkalmazandó díjtarifa szerint csak a javukra vonatkozóan módosulhat, továbbá a szerződés a szerződő fél javára hogyan módosul.</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p>&nbsp;</p>
          <div class="container">
            <div class="row justify-content-center">
              <p class="question">
                <b>Milyen esetben és kinek térít a biztosító?</b>
              </p>
              <p>
                A kötelező gépjármű-felelősségbiztosítás olyan biztosítás, amely alapvetően a gépjármű
                üzemeltetése során másnak okozott károkat téríti a károsult személynek.
              </p>
              <p class="question">
                <b>Tényleg kötelező a kötelező?<u></u></b>
              </p>
              <p>
                A gépjármű-felelősségbiztosítási szerződés megkötését jogszabály írja elő, mely
                szerint a jármű tulajdonjogának megszerzésétől kezdődően a közúti forgalomban való
                részvétel kötelező feltétele a biztosítás megléte.
              </p>
              <p class="question">
                <b>Mi célt szolgál?</b>
              </p>
              <p>
                A vétlen károsult akkor is teljes körű kártérítést kap, ha a károkozó anyagi lehetőségei
                ezt nem teszik lehetővé.
              </p>
              <p class="question">
                <b>
                  Mikor lépett hatályba a kötelező gépjármű-felelősségbiztosításról szóló 2009. évi
                  LXII. törvény?
                </b>
              </p>
              <p>
                2010. 01. 01.
              </p>
              <p class="question">
                <b>Ki a biztosítás elsődleges kötelezettje?</b>
              </p>
              <p>
                A jármű mindenkori bejegyzett üzembentartója köteles biztosítást kötni.
              </p>
              <p class="question">
                <b>Mikor kezdődik a biztosító kockázatviselése?</b>
              </p>
              <p>
                A szerződés létrejön, ha az üzembentartó a meghirdetett díjszabásnak és szerződési
                feltételeknek megfelelő ajánlatát átadja a biztosítónak vagy a biztosító képviselőjének.
                A szerződés létrejöttével egy időben a szerződés hatályba is lép, vagyis elkezdődik
                a biztosító kockázatviselése.
              </p>
              <p class="question">
                <b>Érvényesek-e a már megkötött Sofőr felelősségbiztosítások?</b>
              </p>
              <p>
                A Pénzügyi Szervezetek Állami Felügyelete 2012. november 10-én meghozott H-FH-II-B-1020/2012.
                számú határozatában megtiltotta a Biztosítónak a Sofőr Felelősségbiztosítás” elnevezésű
                termékre vonatkozó biztosítási tevékenység végzését. A tiltás a határozatnak a Biztosító
                által történt kézhezvételétől, 2012. november 10. napjától hatályos. Ezen időponttól
                tehát a Biztosító a terméket nem terjesztheti, az ügyfelek által a Sofőr Felelősségbiztosítási
                szerződés megkötésére teendő ajánlatokat nem fogadhatja el.
              </p>
              <p>
                A határozat kézhezvételéig már megkötött Sofőr Felelősségbiztosítási szerződések
                létét és érvényességét azonban a Felügyelet döntése nem érinti, ezért Társaságunk
                ezen szerződéseket a tartalmukat képező Sofőr Felelősségbiztosítás feltételei szerint
                teljesíti és teljesíteni köteles.
              </p>
              <h2>Gépjármű biztosítások flottáknak</h2>
              <p class="align_justify">
                Megnyerő díjak és feltételek, egyszerűsített ügymenet - flottaszerződéseinkkel ezeket
                az előnyöket kínáljuk.
              </p>
              <p class="question">
                <b>A törvény hogyan fogalmazza meg a gépjárműflotta fogalmát?</b>
              </p>
              <p>
                Legalább öt gépjármű esetén lehetőséget nyújtunk a gépjárművek egyedi biztosítása
                helyett a teljes járműpark flottaszerződésben történő biztosítására. A szerződéskötés
                további fontos feltétele, hogy az egy flottában biztosított gépjárműveknél egyazon
                jogi személy vagy vállalkozó legyen a forgalmi engedélybe üzembentartóként, vagy
                a törzskönyvbe tulajdonosként bejegyezve.
              </p>
              <p>
                Flotta szerződéskötés esetében bonus/malus kategória nem kerül megállapításra.
              </p>
              <p class="question">
                <b>További információra van szüksége?</b>
              </p>
              <p>
                <a [routerLink]="['/gyik']">Gyakran ismételt kérdések menü alatt </a>részletes tájékoztatást
                nyújtunk Önnek a kötelező gépjármű-felelősségbiztosítás szolgáltatásáról, a bonus-malus
                rendszerről, a szerződés megkötésére és megszűntetésére vonatkozó tudnivalókról,
                a kockázatban állásról és a kárügyintézésről.
              </p>
              <p>
                Kérdés esetén készséggel állunk rendelkezésére hívja bizalommal ügyfélszolgálati
                munkatársainkat. Adminisztratív ügyintézés és Kárrendezési hely: 1211 Budapest,
                Szállító u. 4. Tel.: +36-1-6666-200
              </p>
              <h2 class="subtitle">
                <b>Vonatkozó jogszabályok</b>
              </h2>
              <ul class="ulstyle">
                <li>2009. évi LXII. törvény a kötelező gépjármű-felelősségbiztosításról</li>
                <li>21/2011. (VI. 10.) NGM rendelet a bonus-malus rendszer, az abba való besorolás, illetve a kártörténeti igazolások kiadásának szabályairól</li>
                <li>20/2009. (X. 9.) PM rendelet a kötelező gépjármű-felelősségbiztosításnál alkalmazott gépjármű-kategóriákról</li>
                <li>34/2009. (XII. 22.) PM rendelet a magyarországi telephelyű gépjárművek, továbbá más tagállami telephelyű és magyarországi rendeltetési hellyel rendelkező gépjárművek kötelező gépjármu-felelősségbiztosítási fedezetének fennállását tanúsító igazolóeszközökre vonatkozó szabályokról</li>
              </ul>

              <h2 class="subtitle"><strong>A biztosítási módozathoz tartozó számlaszám</strong></h2>
              <table style="width: 100%;">
                <tr>
                  <td><span>10918001-00000068-64440037</span></td>
                  <td>IBAN: <span>HU05 1091 8001 0000 0068 6444 0037</span></td>
                </tr>
              </table>

              <h2 class="subtitle">
                <b>Letölthető dokumentumok</b>
              </h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_kgfb.pdf">KGFB - Biztosítási termékismertető</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_144_20240616.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF144 2024.06.16-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20240215.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2024.02.15-től hatályos)</a><br /></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_tavertekesitesi_tajekoztato_20240215.pdf">Tájékozató távértékesítés keretében kötött kötelező gépjármű-felelősségbiztosítási szerződéshez (2024.02.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}2009_evi_lxii_tv.pdf">2009. évi LXII. törvény a kötelező gépjármű-felelősségbiztosításról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}21_2011_ngm_rendelet.pdf">21/2011. (VI. 10.) NGM rendelet a bonus-malus rendszer, az abba való besorolás, illetve a kártörténeti igazolások kiadásának szabályairól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}pm_20_2009.pdf">20/2009. (X. 9.) PM rendelet a kötelező gépjármű-felelősségbiztosításnál alkalmazott gépjármű-kategóriákról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}pm_34_2009.pdf">34/2009. (XII. 22.) PM rendelet a magyarországi telephelyű gépjárművek, továbbá más tagállami telephelyű és magyarországi rendeltetési hellyel rendelkező gépjárművek kötelező gépjármű-felelősségbiztosítási fedezetének fennállását tanúsító igazolóeszközökre vonatkozó szabályokról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}csop_besz_megbizas_20120921.pdf">Felhatalmazás csoportos beszedési megbízás teljesítésére</a></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20250301.pdf">Díjtáblázat 2025.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20250201.pdf">Díjtáblázat 2025.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20250101.pdf">Díjtáblázat 2025.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtablazat_2025.pdf">Fedezetlenségi díjtábla 2025.</a></li>
              </ul>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20241201.pdf">Díjtáblázat 2024.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20241101.pdf">Díjtáblázat 2024.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20241001.pdf">Díjtáblázat 2024.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240901.pdf">Díjtáblázat 2024.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240801.pdf">Díjtáblázat 2024.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240716.pdf">Díjtáblázat 2024.07.16-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240701.pdf">Díjtáblázat 2024.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240601.pdf">Díjtáblázat 2024.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240501.pdf">Díjtáblázat 2024.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240401.pdf">Díjtáblázat 2024.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240301.pdf">Díjtáblázat 2024.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240201.pdf">Díjtáblázat 2024.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20240101.pdf">Díjtáblázat 2024.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2024.pdf">Fedezetlenségi díjtábla 2024.</a></li>
              </ul>
              <h2 class="subtitle">
                <a name="dl"></a><strong>Kárbejelentési dokumentumok</strong>
              </h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_karesemeny.pdf">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítás biztosítottjának gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_biztositott_nyilatkozat_adatkez_tajek_karesemeny.pdf">Biztosított nyilatkozata a kötelező gépjármű-felelősségbiztosítási gépjármű káreseményhez és adatkezelési tájékozató kötelező gépjármű-felelősségbiztosítás biztosítottjának gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_biztositott_nyilatkoz_visszavonas.pdf">Kötelező gépjármű-felelősségbiztosítási szerződés biztosítottjának nyilatkozata a személyes adatok kezeléséhez adott hozzájárulása visszavonásáról</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_bergepjarmu_igenybevet_karterit_igeny_eseten.pdf">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási káreseményben megsérült gépjármű esetén a bérgépjármű igénybevételével kapcsolatos kártérítési igény felmérésére vonatkozó adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatlap_bergepjarmu_igenybevet_karterit_igeny.pdf">Adatlap bérgépjármű igénybevételével kapcsolatos kártérítési igény felmérésére </a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_tajek_bergepjarmu_koltsegigeny_terit_felt.pdf">Tájékoztató bérgépjármű költségigény térítésének feltételeiről</a><br />
                </li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ertekcsokkenes_alkalmazasanak_modszertana.pdf">Értékcsökkenés alkalmazásának módszertana</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kgfb_karbejelentohoz.pdf">Adatkezelés tájékoztató a kötelező gépjármű-felelősségbiztosítási gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}karbejelento_nyomtatvany_kgfb.pdf">Kárbejelentő nyomtatvány kötelező gépjármű-felelősségbiztosítási gépjármű káreseményhez és adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_szemelyi_serult_karbejelentesi_nyomtatvany_es_adatkez_tajek.pdf">Személyi sérültek kárbejelentési nyomtatványa a gépjárművek kötelező felelősségbiztosítása terhére történő kárrendezéshez és adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási személyi sérüléses káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_szemelyi_serult_karesemeny.pdf">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási személyi sérüléses káresemény bejelentésével kapcsolatos adatkezeléshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_egeszsegugyi_adatok_kezelesehez.pdf">Nyilatkozat egészségügyi adat kezeléséhez</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_es_egeszsegugyi_adatok_kezelesenek_visszavonasarol.pdf">Nyilatkozat személyes és egészségügyi adatok kezeléséhez adott hozzájárulás visszavonásáról</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kerdoiv_kgfb_uvegkarhoz_20120921.pdf">Kérdőív KGFB üvegkárhoz</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szamla_nelkuli_karrendezeshez_20160101.pdf">Nyilatkozat számla nélküli kárrendezéshez</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_szemelyes_adatok_kezelesehez_adott_hozzajarulas_visszavonasarol_201810.pdf">Nyilatkozat személyes adatok kezeléséhez adott hozzájárulás visszavonásáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}nyilatkozat_afa_visszaigenylesrol.pdf">Nyilatkozat ÁFA visszaigénylésről</a></li>
              </ul>
              <h2 class="subtitle">
                <strong>Archívum</strong>
              </h2>
              <ul>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_143_20240215.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF143 2024.02.15-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20231201.pdf">Díjtáblázat 2023.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20231101.pdf">Díjtáblázat 2023.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20231001.pdf">Díjtáblázat 2023.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230901.pdf">Díjtáblázat 2023.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230801.pdf">Díjtáblázat 2023.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230701.pdf">Díjtáblázat 2023.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230601.pdf">Díjtáblázat 2023.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230501.pdf">Díjtáblázat 2023.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230401.pdf">Díjtáblázat 2023.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230315.pdf">Díjtáblázat 2023.03.15-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230301.pdf">Díjtáblázat 2023.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230201.pdf">Díjtáblázat 2023.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20230101.pdf">Díjtáblázat 2023.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2023.pdf">Fedezetlenségi díjtábla 2023.</a></li>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_142_20240101.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF142 2024.01.01-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20230615.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2023.06.15-től hatályos)</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_tavertekesitesi_tajekoztato.pdf">Tájékozató távértékesítés keretében kötött kötelező gépjármű-felelősségbiztosítási szerződéshez</a></li>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_141_20231223.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF141 2023.12.23-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_140_20230615.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF140 2023.06.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_139_20230301.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF139 2023.03.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20211220.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.12.20-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_138_20211220.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF138 2021.12.20-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20221201.pdf">Díjtáblázat 2022.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20221101.pdf">Díjtáblázat 2022.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20221001.pdf">Díjtáblázat 2022.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220901.pdf">Díjtáblázat 2022.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220801.pdf">Díjtáblázat 2022.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220701.pdf">Díjtáblázat 2022.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220604.pdf">Díjtáblázat 2022.06.04-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220601.pdf">Díjtáblázat 2022.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220501.pdf">Díjtáblázat 2022.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220401.pdf">Díjtáblázat 2022.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220301.pdf">Díjtáblázat 2022.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220201.pdf">Díjtáblázat 2022.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20220101.pdf">Díjtáblázat 2022.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2022.pdf">Fedezetlenségi díjtábla 2022.</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20212101.pdf">Díjtáblázat 2021.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20211101.pdf">Díjtáblázat 2021.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20211009.pdf">Díjtáblázat 2021.10.09-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20211001.pdf">Díjtáblázat 2021.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210901.pdf">Díjtáblázat 2021.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210801.pdf">Díjtáblázat 2021.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210701.pdf">Díjtáblázat 2021.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210601.pdf">Díjtáblázat 2021.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210501.pdf">Díjtáblázat 2021.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210401.pdf">Díjtáblázat 2021.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210301.pdf">Díjtáblázat 2021.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210201.pdf">Díjtáblázat 2021.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20210101.pdf">Díjtáblázat 2021.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2021.pdf">Fedezetlenségi díjtábla 2021.</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_137_20210626.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF137 2021.06.26-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20210501.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2021.05.01-től hatályos)</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_136_20210501.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF136 2021.05.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20201201.pdf">Díjtáblázat 2020.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20201101.pdf">Díjtáblázat 2020.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20201001.pdf">Díjtáblázat 2020.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200901.pdf">Díjtáblázat 2020.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200801.pdf">Díjtáblázat 2020.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200701.pdf">Díjtáblázat 2020.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200601.pdf">Díjtáblázat 2020.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200501.pdf">Díjtáblázat 2020.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200401.pdf">Díjtáblázat 2020.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200301.pdf">Díjtáblázat 2020.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200201.pdf">Díjtáblázat 2020.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20200101.pdf">Díjtáblázat 2020.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2020.pdf">Fedezetlenségi díjtábla 2020.</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_135_20200701.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF135 2020.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20200701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2020.07.01-től hatályos)</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_134_20190426.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF134 2019.04.26-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20190426.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2019.04.26-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20191201.pdf">Díjtáblázat 2019.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20191101.pdf">Díjtáblázat 2019.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20191001.pdf">Díjtáblázat 2019.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190901.pdf">Díjtáblázat 2019.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190801.pdf">Díjtáblázat 2019.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190701.pdf">Díjtáblázat 2019.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190601.pdf">Díjtáblázat 2019.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190501.pdf">Díjtáblázat 2019.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190401.pdf">Díjtáblázat 2019.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190301.pdf">Díjtáblázat 2019.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190201.pdf">Díjtáblázat 2019.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20190101.pdf">Díjtáblázat 2019.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2019.pdf">Fedezetlenségi díjtábla 2019.</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20181201.pdf">Díjtáblázat 2018.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20181101.pdf">Díjtáblázat 2018.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20181001.pdf">Díjtáblázat 2018.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180901.pdf">Díjtáblázat 2018.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180801.pdf">Díjtáblázat 2018.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180701.pdf">Díjtáblázat 2018.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180601.pdf">Díjtáblázat 2018.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180501.pdf">Díjtáblázat 2018.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180401.pdf">Díjtáblázat 2018.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180301.pdf">Díjtáblázat 2018.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180201.pdf">Díjtáblázat 2018.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180102.pdf">Díjtáblázat 2018.01.02-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2018.pdf">Fedezetlenségi díjtábla 2018.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20180101.pdf">Díjtáblázat 2018.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_133_20190101.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF133 2019.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20181105.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.11.05-től hatályos)</a><br /></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_132_20180808.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF132 2018.08.08-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180808.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.08.08-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_131_20180701.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF131 2018.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_130_20180525.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF130 2018.05.25-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180525.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.05.25-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_129_20180223.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF129 2018.02.23-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180223.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.02.23-tól hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_128_20180101.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF128 2018.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20180101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2018.01.01-től hatályos)</a></li>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20171201.pdf">Díjtáblázat 2017.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20171101.pdf">Díjtáblázat 2017.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20171001.pdf">Díjtáblázat 2017.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170901.pdf">Díjtáblázat 2017.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170801.pdf">Díjtáblázat 2017.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170701.pdf">Díjtáblázat 2017.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170601.pdf">Díjtáblázat 2017.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170501.pdf">Díjtáblázat 2017.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170401.pdf">Díjtáblázat 2017.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170301.pdf">Díjtáblázat 2017.03.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170201.pdf">Díjtáblázat 2017.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2017.pdf">Fedezetlenségi díjtábla 2017.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20170101.pdf">Díjtáblázat 2017.01.01-től</a></li>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_127_20170701.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF127 2017.07.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170601.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.06.01-től hatályos)</a><br />
                </li>

                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_126_20170601.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF126 2017.06.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_125_20170401.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF125 2017.04.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170401.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.04.01-tól hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_124_20170101.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF124 2017.01.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20170101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2017.01.01-tól hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20161201.pdf">Díjtáblázat 2016.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20161101.pdf">Díjtáblázat 2016.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20161001.pdf">Díjtáblázat 2016.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160901.pdf">Díjtáblázat 2016.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160801.pdf">Díjtáblázat 2016.08.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160701.pdf">Díjtáblázat 2016.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160601.pdf">Díjtáblázat 2016.06.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160501.pdf">Díjtáblázat 2016.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160401.pdf">Díjtáblázat 2016.04.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160301.pdf">Díjtáblázat 2016.03.01-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160218.pdf">Díjtáblázat 2016.02.18-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160201.pdf">Díjtáblázat 2016.02.01-től</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20160101.pdf">Díjtáblázat 2016.01.01-től</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2016.pdf">Fedezetlenségi díjtábla 2016.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_123_20160901.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF123 2016.09.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160901.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.09.01-tól hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_122_20160701.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF122 2016.07.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160701.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.07.01-tól hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_121_20160401.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF121 2016.04.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20160102.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2016.01.02-tól hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_120_20160101.pdf">KGFB Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók és Általános Szerződési Feltételek (ÁSZF120 2016.01.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20151201.pdf">Díjtáblázat 2015.12.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20151101.pdf">Díjtáblázat 2015.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20151001.pdf">Díjtáblázat 2015.10.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150901.pdf">Díjtáblázat 2015.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150715.pdf">Díjtáblázat 2015.07.15-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150701.pdf">Díjtáblázat 2015.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150501.pdf">Díjtáblázat 2015.05.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150402.pdf">Díjtáblázat 2015.04.02-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150315.pdf">Díjtáblázat 2015.03.15-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150218.pdf">Díjtáblázat 2015.02.18-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20150101.pdf">Díjtáblázat 2015.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2015.pdf">Fedezetlenségi díjtábla 2015.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_119_20150101.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF119 2015.01.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20150101.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2015.01.01-től hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20141101.pdf">Díjtáblázat 2014.11.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140901.pdf">Díjtáblázat 2014.09.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140701.pdf">Díjtáblázat 2014.07.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140602.pdf">Díjtáblázat 2014.06.02-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140402.pdf">Díjtáblázat 2014.04.02-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_118_20140315.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF118 2014.05.15-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140316.pdf">Díjtáblázat 2014.03.16-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140308.pdf">Díjtáblázat 2014.03.08-tól</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140201.pdf">Díjtáblázat 2014.02.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_117_20140201.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF117 2014.02.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20140101.pdf">Díjtáblázat 2014.01.01-től</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2014.pdf">Fedezetlenségi díjtábla 2014.</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_116_20131101.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF116 2013.11.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20131101.pdf">Díjtáblázat 2013.11.01-től</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kozlemeny_h-fh-ii-b-26-2013.pdf">KÖZLEMÉNY a Pénzügyi Szervezetek Állami Felügyeletének H-FH-II-B-26/2013. számú határozatáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20131001.pdf">Ügyfél- és Adatkezelési tájékoztató, Hasznos tudnivalók (2013.10.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kozlemeny_20130528.pdf">Közlemény a 2013.06.02-től érvényes tarifához</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20130602.pdf">Díjtáblázat 2013.06.02-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20130402.pdf">Díjtáblázat 2013.04.02-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_20130304.pdf">Díjtáblázat 2013.03.04-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kozlemeny_h-fh-ii-6-2013.pdf">KÖZLEMÉNY a Pénzügyi Szervezetek Állami Felügyeletének H-FH-II-6/2013. számú határozatáról</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_2013.pdf">Díjtáblázat 2013.01.01-től</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_dijak_2013_helyreig_ny.pdf">2013. évi KGFB tarifa hirdetménypontosítás I.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_dijak_2013_helyreig_ny2.pdf">2013. évi KGFB tarifa hirdetménypontosítás II.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_2013_egyseges_szerkezetben.pdf">2013. évi KGFB tarifa és módosítások egységes szerkezetbe foglalva</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}sofor_felelossegbiztositas.pdf">Sofőr felelősségbiztosítási feltételek</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2013.pdf">Fedezetlenségi díjtábla 2013</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_115_3_20130301.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF115/3 2013.03.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130701.pdf">Ügyféltájékoztató (2013.07.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20130301.pdf">Ügyféltájékoztató (2013.03.01-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_115-2.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF115/2 2013.01.01-től hatályos)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_115_20130101.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF115 2013.01.01-től hatályos)</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_2012.pdf">Díjtáblázat 2012.01.01-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kiegeszites_wabardbiztositozrt.pdf">Díjtáblázat kiegészítés 2012.01.01-től hatályos</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2012.pdf">Fedezetlenségi díjtábla 2012.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_adatkez_taj_20120120.pdf">Ügyfél- és Adatkezelési tájékoztató 2012. január 20-ától</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_114_20120921.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF114 2012.09.21-től hatályos)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_114.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF114)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}gepjarmu_karbejelento.pdf">Kárbejelentő lap</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20120101.pdf">Ügyféltájékoztató 2012. január 1-jétől</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}ugyfel_taj_20110701.pdf">Ügyféltájékoztató 2011. július 1-jétől</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_2011.pdf">Díjtáblázat 2011.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_aszf_113.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF113)</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla_2011.pdf">Fedezetlenségi díjtábla 2011.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}csop_besz_megbizas.pdf">Felhatalmazás csoportos beszedési megbízás teljesítésére</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_uftk102_20081028.pdf">Ügyfél-tájékoztató 2011. október 28-jétől</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_2010.pdf">Díjtáblázat 2010.</a></li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}aszf112.pdf">KGFB Ügyfél-tájékoztató és Általános Szerződési Feltételek (ÁSZF112)</a></li>
                <li>
                  <a class="download" target="_blank" href="{{weboldalDokumentumok}}fedezetlensegi_dijtabla.pdf">Fedezetlenségi díjtábla 2010.</a><br />
                </li>
                <li><a class="download" target="_blank" href="{{weboldalDokumentumok}}kgfb_dijtablazat_2009.pdf">Díjtáblázat 2009.</a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
