<div class="bg-primary mol-grid-wrapper" style="">
  <section class="text-white mb-0 pt-5 kosd-mol-grid-header">
  </section>
  <section class="mb-4 pb-3  text-white mol-grid-body" style="">
    <div class="container pt-5 pb-5">
      <div class="row pt-4">
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="container">
          <div class="row justify-content-center kosd-mol-hero-box">
            <div class="col-12 col-lg-5 py-4 px-lg-1 d-flex flex-column align-items-start justify-content-start text-primary">
              <h1 class="bold px-lg-4">Fedezd fel a világot biztonságban!</h1>
              <div class="px-lg-4">
                Kösd meg az utasbiztosítást, és nyerj!
              </div>
              <div class="px-lg-4 font-info">
                * MOL GROUP kártyán, felhasználható a kiállítástól számított 1 évig
              </div>
              <div class="px-lg-4 font-info">
                ** Egyedi kód használatával, harmadik feles ajánlatok elfogadása mellett
              </div>
            </div>

            <div class="col-lg-6">

              <form class="needs-validation position-relative px-lg-5" id="KosdForm" novalidate style="z-index:4;" [formGroup]="formGroup">
                <div class="bg-primary d-flex flex-column form-box rounded-4">
                  <div class="form-box-form bg-info p-5 text-dark rounded-4 rounded-bottom-0 shadow-lg">
                    <div class="row">
                      <div class="col-12 col-lg-6 pb-4">
                        <div class="bold text-start text-primary h3">
                          Utasbiztosítás kalkulátor
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 mb-3">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 25" width="46" height="25">
                          <title>MOL_MOVE_LOGO-02-ai</title>
                          <defs>
                            <linearGradient id="g1" x2="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(36.833,0,0,24.903,9.102,.104)">
                              <stop offset="0" stop-color="#e2d26c" />
                              <stop offset="1" stop-color="#a78538" />
                            </linearGradient>
                          </defs>
                          <style>
                            .s0 {
                              fill: url(#g1)
                            }

                            .s1 {
                              fill: #fefefe
                            }

                            .s2 {
                              fill: #a78538
                            }

                            .s3 {
                              fill: #a0ce67
                            }

                            .s4 {
                              fill: #ee3f37
                            }
                          </style>
                          <g id="Layer 3">
                            <g id="&lt;Group&gt;">
                              <path id="&lt;Path&gt;" class="s0" d="m39 0.1h-11.6c0.7 0.7 1 1.6 1 2.5 0 0.8 0 3.2-7.2 8.3-3.7 2.7-7.8 4.9-9.4 5.8q-1.6 0.9-2.7 1.1c0 0.6 0 1 0 1.1 0.4 3.4 3.3 6.1 6.9 6.1h23c3.8 0 6.9-3.1 6.9-6.9v-11.1c0-3.8-3.1-6.9-6.9-6.9z" />
                              <g id="&lt;Group&gt;">
                                <path id="&lt;Compound Path&gt;" class="s1" d="m16.7 16.2h2.2l1 2.2 1.7-2.2h2.2l-1.1 6.3h-2.1l0.5-3.1-1.8 2.2-1.1-2.2-0.5 3.1h-2.1z" />
                                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="s1" d="m26.7 22.6q-0.7 0-1.3-0.3-0.5-0.2-0.9-0.6-0.4-0.5-0.6-1.1-0.1-0.6 0-1.2v-0.1q0.1-0.6 0.5-1.2 0.4-0.6 0.9-1.1 0.5-0.4 1.2-0.7 0.7-0.2 1.4-0.2 0.7 0 1.3 0.2 0.6 0.3 0.9 0.7 0.4 0.4 0.6 1 0.1 0.6 0 1.3-0.1 0.7-0.5 1.3-0.3 0.6-0.9 1-0.5 0.5-1.2 0.7-0.7 0.3-1.4 0.3zm0.4-1.9q0.3 0 0.5-0.1 0.3-0.1 0.5-0.3 0.2-0.2 0.3-0.4 0.2-0.3 0.2-0.5v-0.1q0.1-0.2 0-0.5 0-0.3-0.2-0.5-0.1-0.1-0.3-0.3-0.2-0.1-0.6-0.1-0.2 0-0.5 0.1-0.3 0.2-0.5 0.3-0.2 0.2-0.3 0.5-0.1 0.2-0.2 0.5 0 0.3 0 0.6 0.1 0.2 0.2 0.4 0.1 0.2 0.3 0.3 0.3 0.1 0.6 0.1z" />
                                <path id="&lt;Compound Path&gt;" class="s1" d="m31.1 16.2h2.3l0.6 3.5 1.9-3.5h2.3l-3.7 6.3h-2z" />
                                <path id="&lt;Compound Path&gt;" class="s1" d="m38.5 16.2h5.3l-0.3 1.7h-3.3l-0.1 0.7h3.1l-0.3 1.5h-3l-0.2 0.6h3.3l-0.3 1.8h-5.3z" />
                              </g>
                              <g id="&lt;Group&gt;">
                                <path id="&lt;Path&gt;" class="s1" d="m38.7 13c-3.1 0-5.6-2.5-5.6-5.6 0-3.1 2.5-5.6 5.6-5.6 3.1 0 5.6 2.5 5.6 5.6 0 3.1-2.5 5.6-5.6 5.6z" />
                              </g>
                              <g id="&lt;Group&gt;">
                                <path id="&lt;Path&gt;" class="s2" d="m41.9 7.9c-0.3 0-0.5-0.2-0.5-0.5 0-0.3 0.2-0.5 0.5-0.5 0.3 0 0.5 0.2 0.5 0.5 0 0.3-0.2 0.5-0.5 0.5z" />
                                <path id="&lt;Path&gt;" class="s2" d="m40.9 7.3c-0.3 0-0.5-0.3-0.5-0.5 0-0.3 0.2-0.5 0.5-0.5 0.2 0 0.4 0.2 0.4 0.5 0 0.2-0.2 0.5-0.4 0.5z" />
                                <path id="&lt;Path&gt;" class="s2" d="m40.9 8.6c-0.3 0-0.5-0.3-0.5-0.5 0-0.3 0.2-0.5 0.5-0.5 0.2 0 0.4 0.2 0.4 0.5 0 0.2-0.2 0.5-0.4 0.5z" />
                                <path id="&lt;Path&gt;" class="s2" d="m39.8 6.6c-0.2 0-0.4-0.2-0.4-0.4 0-0.3 0.2-0.5 0.4-0.5 0.3 0 0.5 0.2 0.5 0.5 0 0.2-0.2 0.4-0.5 0.4z" />
                                <path id="&lt;Path&gt;" class="s2" d="m39.8 7.8c-0.2 0-0.4-0.2-0.4-0.4 0-0.2 0.2-0.4 0.4-0.4 0.3 0 0.5 0.2 0.5 0.4 0 0.2-0.2 0.4-0.5 0.4z" />
                                <path id="&lt;Path&gt;" class="s2" d="m39.8 9.1c-0.2 0-0.4-0.2-0.4-0.4 0-0.3 0.2-0.5 0.4-0.5 0.3 0 0.5 0.2 0.5 0.5 0 0.2-0.2 0.4-0.5 0.4z" />
                                <path id="&lt;Path&gt;" class="s2" d="m38.8 5.9c-0.2 0-0.4-0.2-0.4-0.4 0-0.2 0.2-0.3 0.4-0.3 0.2 0 0.4 0.1 0.4 0.3 0 0.2-0.2 0.4-0.4 0.4z" />
                                <path id="&lt;Path&gt;" class="s2" d="m38.8 7.1c-0.2 0-0.4-0.1-0.4-0.3 0-0.2 0.2-0.4 0.4-0.4 0.2 0 0.4 0.2 0.4 0.4 0 0.2-0.2 0.3-0.4 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m38.8 8.4c-0.2 0-0.4-0.2-0.4-0.4 0-0.2 0.2-0.3 0.4-0.3 0.2 0 0.4 0.1 0.4 0.3 0 0.2-0.2 0.4-0.4 0.4z" />
                                <path id="&lt;Path&gt;" class="s2" d="m38.8 9.6c-0.2 0-0.4-0.1-0.4-0.3 0-0.2 0.2-0.4 0.4-0.4 0.2 0 0.4 0.2 0.4 0.4 0 0.2-0.2 0.3-0.4 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m37.8 5.2c-0.2 0-0.3-0.1-0.3-0.3 0-0.2 0.1-0.3 0.3-0.3 0.1 0 0.3 0.1 0.3 0.3 0 0.2-0.2 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m37.8 6.4c-0.2 0-0.3-0.1-0.3-0.3 0-0.1 0.1-0.2 0.3-0.2 0.1 0 0.3 0.1 0.3 0.2 0 0.2-0.2 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m37.8 7.7c-0.2 0-0.3-0.1-0.3-0.3 0-0.2 0.1-0.3 0.3-0.3 0.1 0 0.3 0.1 0.3 0.3 0 0.2-0.2 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m37.8 9c-0.2 0-0.3-0.2-0.3-0.3 0-0.2 0.1-0.3 0.3-0.3 0.1 0 0.3 0.1 0.3 0.3 0 0.1-0.2 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m37.8 10.2c-0.2 0-0.3-0.1-0.3-0.3 0-0.1 0.1-0.3 0.3-0.3 0.1 0 0.3 0.2 0.3 0.3 0 0.2-0.2 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m36.7 4.5c-0.1 0-0.2-0.1-0.2-0.2 0-0.2 0.1-0.3 0.2-0.3 0.2 0 0.3 0.1 0.3 0.3 0 0.1-0.1 0.2-0.3 0.2z" />
                                <path id="&lt;Path&gt;" class="s2" d="m36.7 5.8c-0.1 0-0.2-0.1-0.2-0.3 0-0.1 0.1-0.2 0.2-0.2 0.2 0 0.3 0.1 0.3 0.2 0 0.2-0.1 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m36.7 7c-0.1 0-0.2-0.1-0.2-0.2 0-0.2 0.1-0.3 0.2-0.3 0.2 0 0.3 0.1 0.3 0.3 0 0.1-0.1 0.2-0.3 0.2z" />
                                <path id="&lt;Path&gt;" class="s2" d="m36.7 8.3c-0.1 0-0.2-0.1-0.2-0.3 0-0.1 0.1-0.2 0.2-0.2 0.2 0 0.3 0.1 0.3 0.2 0 0.2-0.1 0.3-0.3 0.3z" />
                                <path id="&lt;Path&gt;" class="s2" d="m36.7 9.5c-0.1 0-0.2-0.1-0.2-0.2 0-0.1 0.1-0.3 0.2-0.3 0.2 0 0.3 0.2 0.3 0.3 0 0.1-0.1 0.2-0.3 0.2z" />
                                <path id="&lt;Path&gt;" class="s2" d="m36.7 10.8c-0.1 0-0.2-0.1-0.2-0.3 0-0.1 0.1-0.2 0.2-0.2 0.2 0 0.3 0.1 0.3 0.2 0 0.2-0.1 0.3-0.3 0.3z" />
                              </g>
                              <g id="&lt;Group&gt;">
                                <path id="&lt;Path&gt;" class="s3" d="m24.6 0.1c2.1 0 2.9 1.3 2.9 2.5 0 4.3-12.9 11.5-16.1 13.3-1 0.5-2.2 1-3.1 1-4.6 0-8.2-8-8.2-12.2 0-2.9 1.1-4.6 3.7-4.6z" />
                                <path id="&lt;Path&gt;" class="s4" d="m7.7 13.6c-2.3-1.3-5.9-4.5-6.4-9-0.2-2 0.4-3.5 2.5-3.5h20.8c1.7 0 1.9 0.9 1.9 1.5 0 0.7-0.6 2.7-6.4 6.8-2.1 1.5-5.2 3.4-7.7 4.6-1.3 0.6-2.6 0.8-4.7-0.4z" />
                                <path id="&lt;Path&gt;" class="s1" d="m7.2 14.2c-2.2-1.3-5.8-4.4-6.4-9.5-0.1-0.8 0-1.7 0.3-2.4 0.2-0.5 0.5-1 1-1.4-1.1 0.5-1.6 1.7-1.6 3.6 0 3.9 3.5 11.3 7.8 11.3 0.3 0 0.8-0.2 1.5-0.4q-1.2-0.2-2.6-1.2z" />
                                <path id="&lt;Path&gt;" class="s1" d="m10 2.6h-1.7l-1 2.7v-2.7h-1.7l-1.8 5.6h1.7l0.6-2.5 0.1 2.5h1.1l1-2.5-0.3 2.5h1.7z" />
                                <path id="&lt;Compound Path&gt;" fill-rule="evenodd" class="s1" d="m14.7 6.6c-0.9 1.4-2.4 2-3.5 1.3-1.1-0.6-1.3-2.3-0.5-3.7 0.9-1.4 2.4-2 3.5-1.4 1.1 0.7 1.3 2.4 0.5 3.8zm-1.8-2.3c-0.5 0-0.9 0.5-1.1 1.1-0.1 0.6 0.2 1.1 0.7 1.1 0.5 0 1-0.5 1.1-1.1 0.1-0.6-0.2-1.1-0.7-1.1z" />
                                <path id="&lt;Path&gt;" class="s1" d="m15 8.2h1.9l1.3-1.7h-1.2l0.8-3.9h-1.7z" />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <label for="fieldKuponkod" class="form-label text-uppercase strong text-primary small ms-3">MOL Move kuponkód:</label>
                        <input #fieldKuponkod type="text" maxlength="10" autocomplete="off" class="form-control mb-0 text-uppercase" id="fieldKuponkod" name="fieldKuponkod" formControlName="f_kuponkod">
                        <label class="form-label text-uppercase strong text-primary small">Az egyedi kódot a MOL Move applikációban az Ajánlataim menüponton belül, a Partnerajánlatok fül alatt találod.</label>
                        <label class="form-label text-uppercase strong text-primary small">Ha érvényes kód nélkül szeretnéd elindítani a kalkulációt, akkor kattints <a class="link" (click)="tovabb()">ide</a></label>
                      </div>

                      <div col-lg-12 mb-3 class="primary-list">

                        <span class="mb-2">Válassz minket, és tedd meg az első lépést a biztonságos utazáshoz!</span>
                        <ul class="pl-4 mb-5 mt-3">
                          <li>Használd az egyedi kódodat a kalkuláció során, és meglepünk egy kuponnal a MOL Move appban!</li>
                          <li>A promóció időtartama alatt 9 darab 100 000 Ft értékű MOL Group Gift kártya kerül kisorsolásra a szerencsés biztosítottak között!</li>
                          <li>Fontos! Mind a kupon, mind a promócióban való részvétel <strong>előfeltétele a sikeres biztosításkötés!</strong></li>
                        </ul>

                      </div>


                      <div class="col-md-12 text-center pt-3">
                        <button type="submit" class="btn btn-lg rounded-pill btn-outlined-primary" (click)="kalkulalok()">Kalkulálok</button>
                      </div>
                    </div>

                  </div>
                </div>
              </form>

            </div>


            <div class="col-md-12 h1 bold py-5 text-start mt-n5">
              Miért válassz<br>
              <span class="text-secondary">Minket?</span>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm rounded-start-4">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd1.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">Napi több ezer</span><br>
                <span>ajánlatkérés</span>
              </div>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm border-dark">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd2.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">350.000</span><br>
                <span>aktív ügyfél</span>
              </div>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm border-dark">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd3.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">4,6 / 5 ÉRTÉKELÉS</span><br>
                <span>több ezer ügyfélértékelés alapján</span>
              </div>
            </div>
            <div class="col-md-3 text-md-center d-flex flex-md-column align-items-md-center p-4 shadow-sm rounded-end-4">
              <div class="mb-2" style="width: 75px">
                <img src="../../../../assets/images/icon-kosd4.svg" class="img-fluid" alt="Gránit Biztosító">
              </div>
              <div class="pt-2 ps-3 ps-md-0">
                <span class="bold text-uppercase h5">15 ÉVE</span><br>
                <span>az Önök szolgálatában</span>
              </div>
            </div>
            <div class="col-md-12 h2 bold py-5 text-start">
              Gyakran ismételt<br>kérdések
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</div>
<section class="bg-info pb-5">
  <div class="container pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="accordion shadow-lg rounded-4" id="accordionGranit">

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                Hogyan vehetek részt a nyereményjátékban?
              </button>
            </h2>
            <div id="collapse0" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Ha sikeresen megkötöd a biztosítást ezen az oldalon keresztül, automatikusan bekerülsz a sorsolásba. A játékszabályzatot <a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_nyeremenyjatek_szabalyzat.pdf">ITT</a> találod.
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                Egyedi kód használata nélkül is megkapom a kupont?
              </button>
            </h2>
            <div id="collapse1" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  Nem. Figyelj arra, hogy helyesen írd be a MOL Move applikációban található egyedi kódodat. A helyes kódot az applikációban az Ajánlataim menüponton belül, a Partnerajánlatok fül alatt tudod ellenőrizni. Ha enélkül kötöd meg az utasbiztosítást, utólag nem lesz lehetőséged érvényesíteni a kódodat.
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item bg-light">
            <h2 class="accordion-header">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                Kérdésem van a promócióval, vagy a biztosítással kapcsolatban. Hova fordulhatok?
              </button>
            </h2>
            <div id="collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  A Gránit Biztosító ügyfélszolgálatát keresd a lap alján található elérhetőségek bármelyikén.
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse0">
                Mikor és hogyan történik a sorsolás?
              </button>
            </h2>
            <div id="collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  A Szervező megbízásából Lebonyolító a Nyereményjáték folyamán ill. lezárását követően összesen 9 (kilenc) darab főnyereményt sorsol ki háromtagú sorsoló bizottság előtt, a véletlenszerűség elvének eleget tevő elektronikus sorsoló program segítségével, közjegyző jelenlétében 2025.január 10. (decemberi hetekben szerződést kötő pályázók) és február 05. (januári hetekben szerződést kötő pályázók) a Lebonyolító székhelyén. A sorsolás nem nyilvános. További részletek a <a class="download" target="_blank" href="{{weboldalDokumentumok}}granit_nyeremenyjatek_szabalyzat.pdf">Nyereményjáték szabályzatban</a>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item bg-light">
            <h2 class="accordion-header ">
              <button class="accordion-button bg-info text-primary bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse0">
                Hogyan és mikor történik a nyertesek közzététele, értesítése?
              </button>
            </h2>
            <div id="collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionGranit">
              <div class="accordion-body accordion-shadow-lg text-light">
                <div class="small w-75">
                  A nyertesek neveit a <a target="_blank" href="https://granitbiztosito.hu/MOLMOVE">https://granitbiztosito.hu/MOLMOVE</a> weboldalon hirdetjük ki. A nyeremény átadására legkésőbb a Nyereményjáték lezárultát követő 60 nappal kerül sor.
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-light py-5">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <h2 class="text-primary strong mb-5">Ügyfeleink mondták</h2>
      </div>
      <div class="col-lg-12">
        <div class="swiper TestimonialSlider">
          <div class="swiper-wrapper">


            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between" *ngFor="let adat of ugyfelElegedettsegLista">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  {{adat}}
                </div>
              </div>
            </div>

            <!--
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  Nagyon köszönöm a gyors ügyintézést. Másnap már az összeg is számlámon volt. Maximálisan elégedett vagyok a kárszakértő kollega hozzáállásával is.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Gabriella (78)</div>
                <div>Budapest</div>
              </div>
            </div>
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  Mindent precízen intéztek. Maximálisan tájékoztatják az ügyfeleket.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Jánosné (63)</div>
                <div>Pápa-Borsosgyőr</div>
              </div>
            </div>
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  A kárszakértő gyorsan felvette velem a kapcsolatot. Rugalmas és türelmes volt. Jól navigált a kamerás hibafelmerésben.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Krisztián (30)</div>
                <div>Miskolc</div>
              </div>
            </div>
            <div class="swiper-slide d-flex flex-column p-3 p-lg-5 border border-light rounded-3 justify-content-between">
              <div class="d-flex">
                <div>
                  <img src="../../../../assets/images/quote.svg">
                </div>
                <div class="ps-3">
                  Nagyon köszönöm a gyors ügyintézést. Másnap már az összeg is számlámon volt. Maximálisan elégedett vagyok a kárszakértő kollega hozzáállásával is.
                </div>
              </div>
              <div class="pt-4 d-flex flex-column">
                <div class="bold text-primary">Gabriella (78)</div>
                <div>Budapest</div>
              </div>
            </div>
            -->
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </div>
</section>
