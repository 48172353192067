import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WeboldalService {
  private url: string = "/api/weboldal";

  constructor(
    private httpClient: HttpClient
  ) { }

  // Település irányítószámból
  async telepulesIrszbol(model: any): Promise<any[]> {
    return await new Promise<any[]>(async (resolve, reject) => {
      return await this.httpClient.post<any[]>(this.url + "/TelepulesIrszbol", model).toPromise().then(
        res => {
          resolve(res);
          console.log(res);
        },
        msg => {
          reject(msg);
          console.log(msg);
        });
    });
  }

  /*telepulesIrszbol(model: any) {

    return this.httpClient.post<any[]>(this.url + "/TelepulesIrszbol", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }*/

  // Ügyfél elégeddetség lista
  ugyfelElegedettseg() {

    return this.httpClient.post<any[]>(this.url + "/UgyfelElegedettseg", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Kösd újra díjszámítás
  dijszamitas(model: any) {

    return this.httpClient.post<any[]>(this.url + "/Dijszamitas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Kösd újra megkötöm
  megkotom(model: any) {

    return this.httpClient.post(this.url + "/Megkotom", model, { responseType: 'text' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }  

  // Szerződött javítók listája
  javitokListaja(model: any) {

    return this.httpClient.post<any[]>(this.url + "/JavitokListaja", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Hash a linkhez
  linkHash(model: any) {

    return this.httpClient.post(this.url + "/LinkHash", model, { responseType: 'text' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // UTAS MOL kuponkód ellenőrzése
  utasMolKupnokodEllenor(model: any) {

    return this.httpClient.post<any>(this.url + "/UtasMolKupnokodEllenor", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}

