<section class="bg-white intro primary-list">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <section class="bg-white text-primary mt-5 mb-4 py-4">
          <div class="container pt-5">
            <div class="row pt-4">
              <div class="col-12 col-lg-10">
                <h1 class="bold">ÁLLÁSAJÁNLATOK</h1>
              </div>
            </div>
          </div>
        </section>
        <section class="mb-4 pb-4">
          <div class="container job-wrapper">
            <div class="row">
              <div class="col-md-6 col-xl-4 px-2">
                <div class="bg-white shadow p-4 h-100">
                  <div class="bold text-primary h4 mb-3">
                    ADMINISZTRÁCIÓS ÜGYINTÉZŐ
                  </div>
                  <div>
                    <p><b>Feladatok:</b></p>
                    <ul>
                      <li>Gépjármű-biztosítási ajánlatok ellenőrzése és feldolgozása</li>
                      <li>Kapcsolódó levelezési feladatok ellátása</li>
                      <li>Kapcsolattartás a társterületekkel</li>
                    </ul>
                    <p><b>Elvárások:</b></p>
                    <ul>
                      <li>Minimum középfokú végzettség (érettségi vizsga megléte kötelező)</li>
                      <li>Biztosítónál vagy biztosításközvetítő cégnél gépjármű-biztosítási területen szerzett minimum 1 éves szakmai tapasztalat</li>
                      <li>Biztosításhoz kapcsolódó jogszabályok ismerete</li>
                      <li>Microsoft Office felhasználói szintű ismerete</li>
                      <li>Önálló munkavégzés, problémamegoldó képesség</li>
                      <li>Együttműködési és kommunikációs készség</li>
                      <li>Precizitás, monotonitás-tűrés, terhelhetőség, csapatmunka</li>
                    </ul>
                    <p><b>Előnyök:</b></p>
                    <ul>
                      <li>BRUNO szerződéskezelő rendszer ismerete</li>
                    </ul>
                    <p><b>Amit nyújtunk:</b></p>
                    <ul>
                      <li>Alap és teljesítmény bér</li>
                      <li>Havi prémium érdekeltség</li>
                      <li>Konszolidált munkahelyi környezet</li>
                    </ul>
                    <p>
                      A fényképes önéletrajzokat bérigény megjelölésével alábbi email címre kérjük megküldeni:<br />
                      <img src="../../../assets/images/email/gabi.png" alt="e-mail">
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-4 px-2">
                <div class="bg-white shadow p-4 h-100">
                  <div class="bold text-primary h4 mb-3">
                    CALL CENTER ÜGYINTÉZŐ
                  </div>
                  <div>
                    <p><b>A leendő munkatárs fő feladatai:</b></p>
                    <ul>
                      <li>A Biztosító ügyfeleinek tájékoztatása, Biztosító termékeinek ajánlása</li>
                      <li>A telefonon beérkező ügyfélkérdések megválaszolása</li>
                      <li>Az ügyféligények gyors, pontos teljesítése</li>
                      <li>A feladathoz kapcsolódó adminisztratív feladatok elvégzése</li>
                      <li>Együttműködés a társosztályokkal</li>
                    </ul>
                    <p><b>Elvárások:</b></p>
                    <ul>
                      <li>Legalább középfokú végzettség</li>
                      <li>Magabiztos felhasználói szintű számítógépes ismeretek (Outlook, Excel, Word)</li>
                      <li>Kiváló kommunikációs készség, lényegre látás, problémamegoldó képesség</li>
                      <li>Pontos, önálló, precíz munkavégzés</li>
                      <li>Pozitív, ügyfélorientált szemlélet</li>
                    </ul>
                    <p><b>Előnyt jelent:</b></p>
                    <ul>
                      <li>Hasonló munkakörben szerzett 2-3 éves gyakorlat</li>
                      <li>Biztosítónál, biztosításközvetítőnél szerzett tapasztalat</li>
                    </ul>
                    <p><b>Amit kínálunk:</b></p>
                    <ul>
                      <li>Hosszú távú munkalehetőség</li>
                      <li>Alapbér és teljesítmény arányos bérezés</li>
                      <li>Családias, támogató légkör</li>
                    </ul>
                    <p><b>Munkavégzés helye:</b></p>
                    <ul>
                      <li>1211 Budapest, Szállító utca 4.</li>
                    </ul>
                    <p>
                      Az önéletrajzokat bérigény megjelölésével az alábbi email címre kérjük megküldeni:<br />
                      <img src="../../../assets/images/email/fa.png" alt="e-mail">
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-4 px-2">
                <div class="bg-white shadow p-4 h-100">
                  <div class="bold text-primary h4 mb-3">
                    <span>Vagyon kárügyintéző pozícióra</span><br />
                    <span style="font-size:18px">(pályakezdők jelentkezését is várjuk)</span>
                  </div>
                  <div>
                    <p><b>A leendő munkatárs fő feladatai:</b></p>
                    <ul>
                      <li>A kárigények fedezeti, jogalapi, és összegszerűségi vizsgálata, döntés, és döntés előkészítése</li>
                      <li>A kárügyekhez csatolt dokumentumok feldolgozása</li>
                      <li>Önálló kárrendezési tevékenység végzése</li>
                      <li>A kárigények elbíráláshoz szükséges levelezések lebonyolítása</li>
                      <li>A kártérítési összegek kifizetésének előkészítése</li>
                      <li>Közvetlen kapcsolattartás megbízott partnerekkel, ügyfelekkel</li>
                      <li>Egyéb adminisztrációs feladatok ellátása</li>

                    </ul>
                    <p><b>Elvárások:</b></p>
                    <ul>
                      <li>Minimum középfokú végzettség</li>
                      <li>Microsoft Office programok felhasználói szintű ismerete</li>
                      <li>Határozottság, gyors és precíz munkavégzés</li>
                      <li>Ügyfélközpontú hozzáállás</li>
                      <li>Terhelhetőség, munkabírás</li>
                    </ul>
                    <p><b>Előnyt jelent:</b></p>
                    <ul>
                      <li>1-2 év hasonló területen szerzett tapasztalat</li>
                      <li>Felsőfokú építész végzettség</li>
                      <li>nyelvtudás</li>
                    </ul>
                    <p><b>Amit kínálunk:</b></p>
                    <ul>
                      <li>Versenyképes jövedelem</li>
                      <li>Alkalmazotti státusz, határozatlan idejű jogviszony</li>
                      <li>Szakmai ismeretek bővítése</li>
                      <li>Támogató, lendületes csapat</li>
                      <li>Stabil, megbízható munkaadói háttér</li>
                    </ul>
                    <p><b>Munkavégzés helye:</b></p>
                    <ul>
                      <li>Közeljövőben Budapest XIII. kerület.</li>
                    </ul>
                    <p>
                      Az önéletrajzokat bérigény megjelölésével az alábbi email címre kérjük megküldeni:<br />
                      <img src="../../../assets/images/email/kar.png" alt="e-mail">
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
