import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NyitolapComponent } from './components/nyitolap/nyitolap.component';
/*Termékek*/
import { KgfbComponent } from './components/termekek/kgfb/kgfb.component';
import { CascoComponent } from './components/termekek/casco/casco.component';
import { EgeszsegComponent } from './components/termekek/egeszseg/egeszseg.component';
import { KafKszfComponent } from './components/termekek/kaf-kszf/kaf-kszf.component';
import { UtasComponent } from './components/termekek/utas/utas.component';
import { OtthonComponent } from './components/termekek/otthon/otthon.component';
import { CmrComponent } from './components/termekek/cmr/cmr.component';
import { VagyonComponent } from './components/termekek/vagyon/vagyon.component';
import { HazmesterComponent } from './components/termekek/hazmester/hazmester.component';
import { MfoComponent } from './components/termekek/mfo/mfo.component';
import { PanoramaComponent } from './components/termekek/panorama/panorama.component';
import { KosdUjraComponent } from './components/termekek/kosd-ujra/kosd-ujra.component';
import { VodafoneComponent } from './components/termekek/vodafone/vodafone.component';
import { MolMoveComponent } from './components/termekek/molmove/molmove.component';
/*Kárrendezés*/
import { KarrendezesComponent } from './components/karrendezes/karrendezes/karrendezes.component';
import { SzerzodottJavitoPartnerekComponent } from './components/karrendezes/szerzodott-javito-partnerek/szerzodott-javito-partnerek.component';
import { KarrendezoPartnerekComponent } from './components/karrendezes/karrendezo-partnerek/karrendezo-partnerek.component';
import { KarrendezesiTajekoztatokComponent } from './components/karrendezes/karrendezesi-tajekoztatok/karrendezesi-tajekoztatok.component';
/*Ügyfeleinknek*/
import { GyikComponent } from './components/ugyfeleinknek/gyik/gyik.component';
import { PanaszbejelentesComponent } from './components/ugyfeleinknek/panaszbejelentes/panaszbejelentes.component';
/*import { OnlinePanaszbejelentesComponent } from './components/ugyfeleinknek/panaszbejelentes/online-panaszbejelentes/online-panaszbejelentes.component';*/
import { FogyasztoiOldalComponent } from './components/ugyfeleinknek/fogyasztoi-oldal/fogyasztoi-oldal.component';
import { PenzugyiNavigatorComponent } from './components/ugyfeleinknek/penzugyi-navigator/penzugyi-navigator.component';
import { KozzetetelekComponent } from './components/ugyfeleinknek/kozzetetelek/kozzetetelek.component';
import { FizetokepessegComponent } from './components/ugyfeleinknek/kozzetetelek/fizetokepesseg/fizetokepesseg.component';
import { Bit108A1Component } from './components/ugyfeleinknek/kozzetetelek/bit108-a1/bit108-a1.component';
import { OnlineFizetesiTajekoztatoComponent } from './components/ugyfeleinknek/online-fizetesi-tajekoztato/online-fizetesi-tajekoztato.component';
import { FizetesiTajekoztatoGyfkComponent } from './components/ugyfeleinknek/fizetesi-tajekoztato-gyfk/fizetesi-tajekoztato-gyfk.component';
/*import { DokumentumIgenylesComponent } from './components/ugyfeleinknek/dokumentum-igenyles/dokumentum-igenyles.component';*/
import { ElegedettsegmeresComponent } from './components/ugyfeleinknek/elegedettsegmeres/elegedettsegmeres.component';
/*Rólunk*/
import { VezetosegComponent } from './components/rolunk/vezetoseg/vezetoseg.component';
import { HirekComponent } from './components/rolunk/hirek/hirek.component';
import { EngedelyekComponent } from './components/rolunk/engedelyek/engedelyek.component';
import { KapcsolatComponent } from './components/rolunk/kapcsolat/kapcsolat.component';
import { AllasajanlatokComponent } from './components/rolunk/allasajanlatok/allasajanlatok.component';
import { ImpresszumComponent } from './components/app-footer/impresszum/impresszum.component';
import { JogiNyilatkozatComponent } from './components/app-footer/jogi-nyilatkozat/jogi-nyilatkozat.component';
import { NevvaltasComponent } from './components/rolunk/nevvaltas/nevvaltas.component';
/* Cookie Consent*/
import { SutikComponent } from './components/sutik/sutik.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: NyitolapComponent },
  /*Footer*/
  { path: 'impresszum', component: ImpresszumComponent },
  { path: 'jogi-nyilatkozat', component: JogiNyilatkozatComponent },
  /*Termékek*/
  { path: 'kgfb', component: KgfbComponent },
  { path: 'casco', component: CascoComponent },
  { path: 'egeszseg', component: EgeszsegComponent },
  { path: 'kaf-kszf', component: KafKszfComponent },
  { path: 'utas', component: UtasComponent },
  { path: 'otthon', component: OtthonComponent },
  { path: 'cmr', component: CmrComponent },
  { path: 'vagyon', component: VagyonComponent },
  { path: 'hazmester', component: HazmesterComponent },
  { path: 'mfo', component: MfoComponent },
  { path: 'panorama', component: PanoramaComponent },
  { path: 'kosd-ujra', component: KosdUjraComponent },
  { path: 'vodafone', component: VodafoneComponent },
  { path: 'molmove', component: MolMoveComponent },
  { path: 'MOLMOVE', component: MolMoveComponent },
  /*{ path: 'vodafone', component: HazmesterComponent },*/
  /*Kárrendezés*/
  { path: 'karrendezes', component: KarrendezesComponent },
  { path: 'szerzodott-javito-partnerek', component: SzerzodottJavitoPartnerekComponent },
  { path: 'karrendezo-partnerek', component: KarrendezoPartnerekComponent },
  { path: 'karrendezesi-tajekoztatok', component: KarrendezesiTajekoztatokComponent },
  /*Ügyfeleinknek*/
  { path: 'gyik', component: GyikComponent },
  { path: 'panaszbejelentes', component: PanaszbejelentesComponent },
  /*{ path: 'online-panaszbejelentes', component: OnlinePanaszbejelentesComponent },*/
  { path: 'fogyasztoi-oldal', component: FogyasztoiOldalComponent },
  { path: 'penzugyi-navigator', component: PenzugyiNavigatorComponent },
  { path: 'kozzetetelek', component: KozzetetelekComponent },
  { path: 'fizetokepesseg', component: FizetokepessegComponent },
  { path: 'bit108-a1', component: Bit108A1Component },
  { path: 'online-fizetesi-tajekoztato', component: OnlineFizetesiTajekoztatoComponent },
  { path: 'fizetesi-tajekoztato-gyfk', component: FizetesiTajekoztatoGyfkComponent },
  /*{ path: 'dokumentum-igenyles', component: DokumentumIgenylesComponent },*/
  { path: 'elegedettsegmeres', component: ElegedettsegmeresComponent },
  /*Rólunk*/
  { path: 'vezetoseg', component: VezetosegComponent },
  { path: 'hirek', component: HirekComponent },
  { path: 'engedelyek', component: EngedelyekComponent },
  { path: 'kapcsolat', component: KapcsolatComponent },
  { path: 'allasajanlatok', component: AllasajanlatokComponent },
  { path: 'nevvaltas', component: NevvaltasComponent },
  { path: 'sutik', component: SutikComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
